html,
body {
  height: 100%;
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

body {
  @include serif-font;
}

hr {
  border-color: $theme-primary;
  border-width: 3px;
  max-width: 50px;
}

hr.light {
  border-color: white;
}

a {
  @include transition-all;
  color: $theme-primary;
  &:hover,
  &:focus {
    color: darken($theme-primary, 10%);
  }
}

.bg-primary a {
  @include transition-all;
  color: white;
  &:hover,
  &:focus {
    color: darken($theme-secondary, 10%);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include sans-serif-font;
}

h2 {
  color: $theme-blue;
}

p {
  font-size: 1.4em;
  line-height: 1.5;
  margin-bottom: 20px;
}

.bg-primary {
  background-color: $theme-primary;
}

.bg-dark {
  background-color: $theme-dark;
  color: white;
}

.text-faded {
  color: rgba(white, 0.7);
}

section {
  padding: 50px 0;
}

aside {
  padding: 50px 0;
}

.no-padding {
  padding: 0;
}

// Navigation

.navbar-default {
  background-color: white;
  border-color: rgba($theme-dark, 0.5);
  @include sans-serif-font;
  @include transition-all;
  .navbar-header .navbar-brand {
    color: $theme-primary;
    @include header-font;
    font-weight: normal;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    &:hover,
    &:focus {
      color: darken($theme-primary, 10%);
    }
  }
  .navbar-header > button {
    border: none !important;
    padding-top: 10px;
  }
  .nav {
    > li {
      > a,
      > a:focus {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 13px;
        color: $theme-dark;
        &:hover {
          color: $theme-primary;
        }
      }
      &.active {
        > a,
        > a:focus {
          color: $theme-secondary !important;
          background-color: transparent;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
    .icon-github {
      font-size: 1.6em;
    }
  }
  @media (min-width: 768px) {
    background-color: transparent;
    border-color: rgba(white, 0.3);
    .navbar-header .navbar-brand {
      color: rgba(white, 0.7);
      &:hover,
      &:focus {
        color: white;
      }
    }
    .nav > li > a,
    .nav > li > a:focus {
      color: $theme-blue;
      &:hover {
        color: white;
      }
    }
    &.affix {
      background-color: white;
      border-color: rgba($theme-dark, 0.5);
      .navbar-header .navbar-brand {
        color: $theme-primary;
        font-size: 14px;
        &:hover,
        &:focus {
          color: darken($theme-primary, 10%);
        }
      }
      .nav > li > a,
      .nav > li > a:focus {
        color: $theme-blue !important;
        &:hover {
          color: $theme-primary;
        }
      }
    }
  }
}

// Homepage Header

header {
  position: relative;
  width: 100%;
  min-height: auto;
  @include background-cover;
  background-position: center;
  background-image: url("/static/img/header.jpg");
  text-align: center;
  color: white;
  .header-content {
    position: relative;
    text-align: center;
    padding: 50px 15px 50px;
    width: 100%;
    .header-content-inner {
      display: flex;
      flex-direction: column;
      gap: 2em;
      h1 {
        @include header-font;
        font-weight: normal;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 0;
      }
      hr {
        margin: 30px auto;
      }
      // p {
      //   font-weight: normal;
      //   color: rgba(white, 0.7);
      //   // font-size: 16px;
      //   font-size: 16px;
      //   margin-bottom: 50px;
      // }
      img {
        width: 100%;
        max-width: 400px;
        padding: 0 20px;
      }
      .header-content-right-text {
        display: none;
      }
    }
  }
  @media (min-width: 768px) {
    padding: 50px 0;
    .header-content {
      padding: 0 50px;
      .header-content-inner {
        flex-direction: row;
        align-items: center;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        .header-content-right-text {
          display: block;
          p {
            font-size: 1.2em;
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
      .header-content-right {
        flex: 1;
      }
    }
  }
}

// Sections

.section-heading {
  margin-top: 0;
}

.service-box {
  max-width: 400px;
  margin: 50px auto 0;
  @media (min-width: 992px) {
    margin: 20px auto 0;
  }
  p {
    margin-bottom: 0;
  }
}

.portfolio-box {
  position: relative;
  display: block;
  max-width: 650px;
  margin: 0 auto;
  .portfolio-box-caption {
    color: white;
    opacity: 0;
    display: block;
    background: rgba($theme-primary, 0.9);
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    @include transition-all;
    .portfolio-box-caption-content {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      .project-category,
      .project-name {
        @include sans-serif-font;
        padding: 0 15px;
      }
      .project-category {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
      }
      .project-name {
        font-size: 18px;
      }
    }
  }
  &:hover {
    .portfolio-box-caption {
      opacity: 1;
    }
  }
  @media (min-width: 768px) {
    .portfolio-box-caption {
      .portfolio-box-caption-content {
        .project-category {
          font-size: 16px;
        }
        .project-name {
          font-size: 22px;
        }
      }
    }
  }
}

.call-to-action {
  h2 {
    margin: 0 auto 20px;
  }
}

// Bootstrap Overrides
.text-primary {
  color: $theme-primary;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

// Button Styles
.btn-margin {
  margin: 5px;
}
.btn-default {
  @include button-variant($theme-dark, white, white);
}

.btn-primary {
  @include button-variant(white, $theme-blue, $theme-primary);
}

.btn {
  @include sans-serif-font;
  border: none;
  border-radius: 0.2em;
  font-weight: 700;
  text-transform: uppercase;
}

.btn-xl {
  padding: 8px 20px;
  // padding: 15px 30px;
}

// Sections
.text-muted {
  color: #555;
}
.bg-primary .text-muted {
  color: #aaa;
}

// Mission and Capabilities
h3.smaller {
  font-size: 1.4em;
}

h3 a:hover {
  text-decoration: none;
}

// Research output
#research {
  figure {
    padding: 0;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

#read-more.btn {
  transition: none;
  margin: 0;
  @media (min-width: 1200px) {
    margin: 8em auto;
  }
}

// Download

#aml-engine {
  padding-top: 0;
  .aml-engine-content {
    background-color: $theme-primary;
    border-radius: 4px;
    padding: 2em 4em 0;
    p {
      color: #aaa;
    }
  }
  .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .read-more.btn {
    transition: none;
    margin-top: 4em;
  }
  @media (min-width: 768px) {
    .flex {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    #read-more.btn {
      margin-top: 0;
    }
  }
}
// #read-more.btn {
//   transition: none;
//   margin: 0;
//   margin-top: 3em;
//   @media (min-width: 1200px) {
//     margin: 8em auto;
//   }
// }

// Sponsors

#support img.sponsorlogo {
  height: 3em;
  margin-top: 1em;
  margin-bottom: 1em;
}

// link icon color
.bg-primary .fa-external-link {
  color: white;
  color: $theme-secondary;
}

// Contact
#contact .fa {
  color: $theme-secondary;
  margin: 0 1em;
}

#mainNav .fa-envelope {
  font-size: 1.5em;
}

#mainNav .fa-twitter {
  font-size: 1.5em;
}

.fa-xs {
  font-size: 0.75em;
  margin-left: 0.35em;
}

// Articles

article {
  padding: 10px;
  @media (min-width: 768px) {
    max-width: 768px;
    margin: auto;
  }
  p {
    font-size: 1.4em;
  }
  .article-date-authors {
    width: 100%;
    font-size: 1.2em;
    text-align: right;
    color: $theme-secondary;
  }
  .article-date-authors time {
    display: block;
  }
  .article-date-authors address {
    display: inline;
  }
}

// Extras
// -- Highlight Color Customization
::-moz-selection {
  color: white;
  text-shadow: none;
  background: $theme-dark;
}

::selection {
  color: white;
  text-shadow: none;
  background: $theme-dark;
}

img::selection {
  color: white;
  background: transparent;
}

img::-moz-selection {
  color: white;
  background: transparent;
}

body {
  -webkit-tap-highlight-color: $theme-dark;
}
